<template>
  <main id="home">
    <b-row>
      <b-col>
        <h1>{{ $t('navbar.greeting') }} {{ user.nome }}</h1>
      </b-col>
    </b-row>

    <b-row> </b-row>
  </main>
</template>

<style scoped>
.limit-width {
  display: inline-block;
  max-width: 450px;
  margin-right: 20px;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('auth');

export default {
  name: 'Home',
  data() {
    return {
      title: 'Dashboard',
      chartOptions: {
        series: [
          {
            data: [1, 2, 3]
          }
        ]
      }
    };
  },
  computed: {
    ...mapState(['user'])
  }
};
</script>
